/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/PageHeader/PageHeader.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import Basics from "components/styleGuide/Basics.js";
import Navbars from "components/styleGuide/Navbars.js";
import Tabs from "components/styleGuide/Tabs.js";
import Pagination from "components/styleGuide/Pagination.js";
import Notifications from "components/styleGuide/Notifications.js";
import Typography from "components/styleGuide/Typography.js";
import JavaScript from "components/styleGuide/JavaScript.js";
import Signup from "components/styleGuide/Signup.js";
import Examples from "components/styleGuide/Examples.js";

class Index extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("index-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <PageHeader />
          <div className="main">
            <Basics />
            <Navbars />
            <Tabs />
            <Pagination />
            <Notifications />
            <Typography />
            <JavaScript />
            <Signup />
            <Examples />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Index;
