/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import StyleGuide from "views/StyleGuide.js";
import LandingPage from "views/LandingPage.js";
import RegisterPage from "views/RegisterPage.js";
import ProfilePage from "views/ProfilePage.js";
import TeamPage from "views/TeamPage.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/style-guide" render={props => <StyleGuide {...props} />} />
      <Route
      exact
        path="/"
        render={props => <LandingPage {...props} />}
      />
      <Route
        path="/register-page"
        render={props => <RegisterPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={props => <ProfilePage {...props} />}
      />
      <Route
        path="/team" 
        render={props => <TeamPage {...props} />}
      />
      {/* <Redirect from="/" to="/landing-page" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
